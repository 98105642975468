import axios from 'axios';
import qs from 'qs';
import AccountModule from '@/store/modules/account';

const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  withCredentials: true,
});

// 免登录api白名单
const loginFreeAPIWhiteList = [
  // '/uc/userInfo',
  '/common/areaList',
];

request.interceptors.request.use(
  // Do something before request is sent
  (conf) => {
    const { data = {} } = conf;
    const newConfig = conf;
    if (window.localStorage.token) {
      data.token = window.localStorage.token;
    }
    newConfig.data = qs.stringify(data);
    if (
      conf.method === 'get'
        && AccountModule.token
        && conf.url
    ) {
      newConfig.url = conf.url.indexOf('?') > -1 ? `${conf.url}&token=${AccountModule.token}` : `${conf.url}?token=${AccountModule.token}`;
    }
    return newConfig;
  },

  // Do something with request error
  (error) => Promise.reject(error),
);

// Add a response interceptor
request.interceptors.response.use(
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  (resp) => {
    const { data } = resp;
    let ret;
    switch (data.code) {
      case 0: {
        ret = Promise.resolve(data);
        break;
      }
      case 401: {
        AccountModule.RemoveToken();
        if (
          resp.config.url
            && loginFreeAPIWhiteList.findIndex(
              (v) => (resp.config.url as string).indexOf(v) > -1,
            ) === -1
        ) {
          // console.log('401', loginFreeAPIWhiteList);
          AccountModule.ToLogin();
        }
        ret = Promise.reject(data);
        break;
      }
      // 402 企业认证审核中
      // 403 企业认证审核拒绝
      // 405 引导用户进入企业认证
      case 402: {
        ret = Promise.reject(data);
        break;
      }
      default: {
        ret = Promise.reject(data);
        break;
      }
    }
    return ret;
  },

  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  (error) => Promise.reject(error),
);

export default request;

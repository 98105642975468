import Vue from 'vue';
import { message } from 'ant-design-vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'normalize.css';
import '@/assets/styles/reset.less';
import '@/assets/styles/diy.css';
import errorHandler from './services/plugins/errorHandler';
// import '@/services/directives/permission';
// import '@/services/directives/longpress';

Vue.config.productionTip = false;
Vue.prototype.$message = message;

Vue.use(errorHandler);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

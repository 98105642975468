// outline
export {
  default as PlusOutline
} from '@ant-design/icons/lib/outline/PlusOutline';
export {
  default as CloseOutline
} from '@ant-design/icons/lib/outline/CloseOutline';
export {
  default as EyeOutline
} from '@ant-design/icons/lib/outline/EyeOutline';
export {
  default as DeleteOutline
} from '@ant-design/icons/lib/outline/DeleteOutline';
export {
  default as PlusCircleOutline
} from '@ant-design/icons/lib/outline/PlusCircleOutline';
export {
  default as CustomerServiceOutline
} from '@ant-design/icons/lib/outline/CustomerServiceOutline';
export {
  default as PieChartOutline
} from '@ant-design/icons/lib/outline/PieChartOutline';
export {
  default as SettingOutline
} from '@ant-design/icons/lib/outline/SettingOutline';
export {
  default as CheckOutline
} from '@ant-design/icons/lib/outline/CheckOutline';
export {
  default as LeftOutline
} from '@ant-design/icons/lib/outline/LeftOutline';
export {
  default as RightOutline
} from '@ant-design/icons/lib/outline/RightOutline';
export {
  default as DownOutline
} from '@ant-design/icons/lib/outline/DownOutline';
export {
  default as UpOutline
} from '@ant-design/icons/lib/outline/UpOutline';
export {
  default as UserOutline
} from '@ant-design/icons/lib/outline/UserOutline';
export {
  default as SolutionOutline
} from '@ant-design/icons/lib/outline/SolutionOutline';
export {
  default as InfoOutline
} from '@ant-design/icons/lib/outline/InfoOutline';
export {
  default as QuestionCircleOutline
} from '@ant-design/icons/lib/outline/QuestionCircleOutline';
export {
  default as UploadOutline
} from '@ant-design/icons/lib/outline/UploadOutline';
export {
  default as CaretDownOutline
} from '@ant-design/icons/lib/outline/CaretDownOutline';

// fill
export {
  default as CloseCircleFill
} from '@ant-design/icons/lib/fill/CloseCircleFill';
export {
  default as CheckCircleFill
} from '@ant-design/icons/lib/fill/CheckCircleFill';
export {
  default as InfoCircleFill
} from '@ant-design/icons/lib/fill/InfoCircleFill';
export {
  default as exclamationCircle
} from '@ant-design/icons/lib/fill/ExclamationCircleFill';
export {
  default as CaretDownFill
} from '@ant-design/icons/lib/fill/CaretDownFill';

import Vue from 'vue';
import Vuex from 'vuex';
import { MSAccountState } from './modules/account.d';
import { MSUserState } from './modules/user.d';

Vue.use(Vuex);

export interface MSStoreState {
  MSAccountState: MSAccountState,
  MSUserState: MSUserState,
}

export default new Vuex.Store<MSStoreState>({});

import Vue from 'vue';
import VueRouter, { RawLocation, RouteConfig } from 'vue-router';
import AccountModule from '@/store/modules/account';
import userCenter from './userCenter';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home/Home.vue'),
  },
  {
    path: '/product/:id',
    name: 'Product',
    component: () => import('../views/Product/Product.vue'),
  },
  {
    path: '/account',
    component: () => import('../views/Account/Index.vue'),
    children: [{
      path: '',
      component: () => import('../views/Account/Login/Index.vue'),
      children: [{
        path: '',
        name: 'AccountLogin',
        component: () => import('../views/Account/Login/Login.vue'),
      }, {
        path: 'changePassword',
        name: 'AccountChangePassword',
        component: () => import('../views/Account/Login/ChangePassword.vue'),
      }],
    }, {
      path: 'regist',
      component: () => import('../views/Account/Regist/Index.vue'),
      children: [{
        path: '',
        name: 'AccountRegistAgreement',
        component: () => import('../views/Account/Regist/Agreement.vue'),
      }, {
        path: 'registrant',
        name: 'AccountRegistRegistrant',
        component: () => import('../views/Account/Regist/Registrant.vue'),
      }, {
        path: 'company',
        name: 'AccountRegistCompany',
        component: () => import('../views/Account/Regist/Company.vue'),
      }, {
        path: 'status',
        name: 'AccountRegistStatus',
        component: () => import('../views/Account/Regist/Status.vue'),
      }],
    }],
  },
  userCenter,
  {
    path: '/cart',
    component: () => import('../views/Cart/Index.vue'),
    children: [{
      path: '',
      name: 'Cart',
      component: () => import('../views/Cart/Cart.vue'),
    }, {
      path: 'confirm',
      name: 'CartConfirm',
      component: () => import('../views/Cart/Confirm.vue'),
    }],
  },
  {
    path: '/order',
    component: () => import('../views/Order/Index.vue'),
    children: [{
      path: 'status',
      name: 'OrderStatus',
      component: () => import('../views/Order/Status.vue'),
    }, {
      path: 'contract',
      name: 'OrderContract',
      component: () => import('../views/Order/Contract.vue'),
    }, {
      path: 'pay',
      name: 'OrderPay',
      component: () => import('../views/Order/Pay.vue'),
    }],
  },
  {
    path: '/test',
    component: () => import('../views/Test.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

const whiteList = [
  'AccountLogin',
  'AccountChangePassword',
  'AccountRegistAgreement',
  'AccountRegistRegistrant',
  'AccountRegistCompany',
  'AccountRegistStatus',
];

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = process.env.VUE_APP_TITLE;
  }

  console.log('AccountModule.loginStatus', AccountModule.loginStatus, to);

  if (AccountModule.loginStatus === null) {
    if (to.name && whiteList.indexOf(to.name) > -1) {
      next();
    } else {
      AccountModule.ToLogin();
    }
    return;
  }

  switch (AccountModule.loginStatus) {
    case 2:
    case 3: {
      if (to.name && whiteList.indexOf(to.name) > -1) {
        next();
      } else {
        next(false);
      }
      break;
    }
    default: {
      next();
      break;
    }
  }
});

export default router;

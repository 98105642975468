
















































import { Component, Vue } from 'vue-property-decorator';
import { ConfigProvider } from 'ant-design-vue';
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import UserModule from '@/store/modules/user';
import AccountModule from './store/modules/account';

Vue.use(ConfigProvider);

// eslint-disable-next-line @typescript-eslint/no-var-requires
const logoUrl = require('@/assets/img/logo.png');

@Component({
  components: {
    'k-login-status': () => import('./components/LoginStatus.vue'),
  },
})
export default class extends Vue {
  readonly zhCN = zhCN;

  url = logoUrl;

  mounted(): void {
    AccountModule.SET_LOGIN_STATUS();
    UserModule.GetUserInfo().then(() => {
      const { logout } = this.$route.query;
      if (logout === '1') {
        AccountModule.RemoveLoginInfo();
        UserModule.REMOVE_USER_INFO();
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  goIndex(): void {
    this.$router.push('/');
  }

  // eslint-disable-next-line class-methods-use-this
  toService(): void{
    const container = document.getElementById('im_chart_btn');
    if (container) {
      container.click();
    }
  }
}

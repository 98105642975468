import request from '../request';
import { CommonResponse } from '../request.d';
import { UserInfoResponse } from './account.d';

// eslint-disable-next-line import/prefer-default-export
export function userInfo(): Promise<CommonResponse<UserInfoResponse>> {
  return request.get(
    '/uc/userInfo',
  );
}

import {
  Module, VuexModule, Mutation, Action, getModule,
} from 'vuex-module-decorators';
import store from '@/store';
import { LoginResponse, Role } from '@/services/apis/user.d';
import { delCookie } from '@/tools/tools';
import { MSAccountState } from './account.d';

const loginSta = localStorage.getItem('ACCOUNT_LOGIN_STATUS');
@Module({ dynamic: true, store, name: 'account' })
class Account extends VuexModule implements MSAccountState {
  token = window.localStorage.token || '';

  loginStatus: LoginResponse['status'] | null = loginSta
    ? parseInt(loginSta, 10) as LoginResponse['status'] : null;

  role: Role = 2;

  previewImgUrl = '';

  get localRole(): Role {
    const role = window.sessionStorage.getItem('ACCOUNT_USER_ROLE');
    if (role) {
      return parseInt(role, 10) as Role;
    }
    return this.role;
  }

  @Mutation
  SET_TOKEN(token: string): void {
    this.token = token;
    if (process.env.NODE_ENV === 'development') {
      document.cookie = `token=${token}`;
    }
  }

  @Mutation
  SET_ROLE(role?: Role): void {
    if (role) {
      this.role = role;
      window.sessionStorage.setItem('ACCOUNT_USER_ROLE', `${role}`);
    } else {
      // 这个机制，会导致切换到供应商后 除非清除缓存，或者访问 退出后更改后缀 role=2,无法切换到融资方
      const r = window.sessionStorage.getItem('ACCOUNT_USER_ROLE');
      if (r) {
        this.role = parseInt(r, 10) as Role;
      }
    }
  }

  @Mutation
  SET_LOGIN_STATUS(loginStatus?: LoginResponse['status']): void {
    if (loginStatus) {
      this.loginStatus = loginStatus;
      window.localStorage.setItem('ACCOUNT_LOGIN_STATUS', loginStatus.toString());
    } else {
      const sta = window.localStorage.getItem('ACCOUNT_LOGIN_STATUS');
      this.loginStatus = sta ? parseInt(sta, 10) as LoginResponse['status'] : null;
    }
  }

  @Mutation
  setPreviewImgUrl(previewImgUrl: string): void{
    this.previewImgUrl = previewImgUrl;
  }

  @Mutation
  REMOVE_TOKEN(): void {
    this.token = '';
    window.localStorage.removeItem('token');
  }

  @Action
  // 设置store和localStorage的token，如果没传则从localStorage取
  SetToken(token?: string): void {
    if (token) {
      this.context.commit('SET_TOKEN', token);
      window.localStorage.token = token;
    } else {
      const tk = window.localStorage.token;
      if (tk) {
        this.context.commit('SET_TOKEN', tk);
      }
    }
  }

  @Action({ commit: 'REMOVE_TOKEN' })
  // eslint-disable-next-line class-methods-use-this
  RemoveToken(): void {
    window.localStorage.removeItem('token');
  }

  @Action
  // eslint-disable-next-line class-methods-use-this
  public ToLogin(): void {
    const url = window.location.href.split('?')[0];
    if (!url.endsWith('/account')) {
      window.location.href = '/account';
    }
  }

  @Action
  RemoveLoginInfo(): void {
    this.RemoveToken();
    delCookie('token');
  }

  @Action
  Logout(): void {
    this.RemoveLoginInfo();
    this.ToLogin();
  }
}

const AccountModule = getModule(Account);

export default AccountModule;

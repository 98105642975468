import { RouteConfig } from 'vue-router';

const UserCenter: RouteConfig = {
  path: '/userCenter',
  component: () => import('../views/UserCenter/Index.vue'),
  children: [{
    path: '',
    name: 'UserCenter',
    component: () => import('../views/UserCenter/UserCenter.vue'),
  }, {
    path: 'order',
    component: () => import('../views/UserCenter/Order/Index.vue'),
    children: [{
      path: '',
      name: 'UserCenterOrder',
      component: () => import('../views/UserCenter/Order/Order.vue'),
    }, {
      path: 'deliveryOrder',
      name: 'UserCenterDeliveryOrder',
      component: () => import('../views/UserCenter/Order/DeliveryOrder.vue'),
    }, {
      path: 'afterSales',
      name: 'UserCenterAfterSales',
      component: () => import('../views/UserCenter/Order/AfterSales.vue'),
    }, {
      path: 'afterSalesDetail',
      name: 'UserCenterAfterSalesDetail',
      component: () => import('../views/UserCenter/Order/AfterSalesDetail.vue'),
    }, {
      path: 'detail/:id',
      name: 'UserCenterOrderDetail',
      component: () => import('../views/UserCenter/Order/Detail.vue'),
    }, {
      path: 'pickup',
      component: () => import('../views/UserCenter/Order/Pickup/Index.vue'),
      children: [{
        path: '',
        name: 'UserCenterPickup',
        component: () => import('../views/UserCenter/Order/Pickup/Pickup.vue'),
      }, {
        path: 'orderDetail',
        name: 'UserCenterPickupOrderDetail',
        component: () => import('../views/UserCenter/Order/Pickup/Detail.vue'),
      }],
    }],
  }, {
    path: 'loan',
    component: () => import('../views/UserCenter/Loan/Index.vue'),
    redirect: {
      name: 'UserCenterFinancingHistory',
    },
    children: [{
      path: 'financingHistory',
      name: 'UserCenterFinancingHistory',
      component: () => import('../views/UserCenter/Loan/FinancingHistory.vue'),
    }, {
      path: 'IOUHistory',
      name: 'UserCenterIOUHistory',
      component: () => import('../views/UserCenter/Loan/IOUHistory.vue'),
    }, {
      path: 'repaymentHistory',
      name: 'UserCenterRepaymentHistory',
      component: () => import('../views/UserCenter/Loan/RepaymentHistory.vue'),
    }],
  }, {
    path: 'capitalFlow',
    component: () => import('../views/UserCenter/CapitalFlow/Index.vue'),
    children: [{
      path: 'capitalAccount',
      name: 'UserCenterCapitalAccount',
      component: () => import('../views/UserCenter/CapitalFlow/CapitalAccount.vue'),
    }, {
      path: '',
      name: 'UserCenterCapitalFlow',
      component: () => import('../views/UserCenter/CapitalFlow/CapitalFlow.vue'),
    }],
  }, {
    path: 'myInfo',
    component: () => import('../views/UserCenter/MyInfo/Index.vue'),
    children: [{
      path: 'capitalAccount',
      name: 'UserCenterMyInfoUserAccount',
      component: () => import('../views/UserCenter/MyInfo/UserAccount.vue'),
    }, {
      path: 'companyInfo',
      name: 'UserCenterMyInfoCompanyInfo',
      component: () => import('../views/UserCenter/MyInfo/CompanyInfo.vue'),
    }, {
      path: 'address',
      name: 'UserCenterMyInfoAddress',
      component: () => import('../views/UserCenter/MyInfo/Address.vue'),
    }, {
      path: 'invoiceInfo',
      name: 'UserCenterMyInfoInvoiceInfo',
      component: () => import('../views/UserCenter/MyInfo/InvoiceInfo.vue'),
    }],
  }, {
    path: 'MyStock',
    component: () => import('../views/UserCenter/MyStock/Index.vue'),
    children: [{
      path: 'StockInfo',
      name: 'MyStockInfo',
      component: () => import('../views/UserCenter/MyStock/StockInfo.vue'),
    }, {
      path: 'InStock',
      name: 'MyStockInStock',
      component: () => import('../views/UserCenter/MyStock/InStock.vue'),
    }, {
      path: 'OutStock',
      name: 'MyStockOutStock',
      component: () => import('../views/UserCenter/MyStock/OutStock.vue'),
    }],
  }],
};

export default UserCenter;

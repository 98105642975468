import {
  Module, VuexModule, Mutation, Action, getModule,
} from 'vuex-module-decorators';
import store from '@/store';
import { userInfo } from '@/services/apis/account';
import { MSUserState, UserInfo } from './user.d';

@Module({ dynamic: true, store, name: 'user' })
class User extends VuexModule implements MSUserState {
  userInfo: UserInfo | null = null;

  @Mutation
  SET_USER_INFO(info: UserInfo): void {
    this.userInfo = info;
  }

  @Mutation
  REMOVE_USER_INFO(): void {
    this.userInfo = null;
  }

  @Action({ commit: 'SET_USER_INFO' })
  async GetUserInfo(isRefresh = false): Promise<UserInfo> {
    if (!isRefresh && this.userInfo) {
      return this.userInfo;
    }
    try {
      const {
        head,
        enterpriseName,
        userName,
        sex,
        mobile,
      } = (await userInfo()).data;
      const info = {
        head,
        enterpriseName,
        userName,
        sex,
        mobile,
      };
      return info;
    } catch (err) {
      return null;
    }
  }
}

const UserModule = getModule(User);

export default UserModule;

import { VueConstructor } from 'vue';
import { message } from 'ant-design-vue';
import { AnyObject, CommonResponse } from '@/services/request.d';

export default {
  install: (Vue: VueConstructor, options = {}): void => {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$errorHandler = (err: Error | CommonResponse<AnyObject>) => {
      console.error('errorHandler: ', err);
      if ('msg' in err) {
        message.error(err.msg);
      } else if (err instanceof Error) {
        message.error(err.message);
      } else {
        console.error('errorHandler: param type error!');
        try {
          message.error(JSON.stringify(err));
        } catch (e) {
          message.error(`未知错误: ${e}`);
        }
      }
    };
  },
};

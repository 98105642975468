import moment from 'moment';

// import AccountModule from '@/store/modules/account';

// export function setPreviewImgUrl(url: string): void {
//   AccountModule.setPreviewImgUrl(url);
// }

export function setCookie(name: string, value: string, day = 0): void {
  // 当设置的时间等于0时，不设置expires属性，cookie在浏览器关闭后删除
  if (day !== 0) {
    const expires = day * 24 * 60 * 60 * 1000;
    const date = new Date(+new Date() + expires);
    document.cookie = `${name}=${escape(value)};expires=${date.toUTCString()}`;
  } else {
    document.cookie = `${name}=${escape(value)}`;
  }
}

export function getCookie(name: string): string | null {
  const reg = new RegExp(`(^| )${name}=([^;]*)(;|$)`);
  const match = document.cookie.match(reg);
  if (match) {
    return unescape(match[2]);
  }
  return null;
}

export function delCookie(name: string): void {
  setCookie(name, ' ', -1);
}

export function getTimeLeft(endTime: string): {
  day: number,
  hour: number,
  minute: number,
  second: number,
} {
  // eslint-disable-next-line new-cap
  const end = new moment(endTime);
  // eslint-disable-next-line new-cap
  const now = new moment();
  const duration = moment.duration(end.diff(now));

  return {
    day: duration.get('days'),
    hour: duration.get('hours'),
    minute: duration.get('minutes'),
    second: duration.get('seconds'),
  };
}

export function getTimeLeftBySeconds(restSeconds: any) : {
  day: number,
  hour: number,
  minute: number,
  second: number,
} {
  const day = Math.floor(restSeconds / (60 * 60 * 24));
  const hour = Math.floor((restSeconds % (60 * 60 * 24)) / (60 * 60));
  const minute = Math.floor((restSeconds % (60 * 60)) / 60);
  const second = restSeconds % 60;
  return {
    day, hour, minute, second,
  };
}
